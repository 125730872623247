import styled from 'styled-components';
import Button from '@@Components/Buttons/Button';
export const ProductCardContainer = styled.div `
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  ${(props) => props.theme.mixins.mobile `
    flex-flow: column;
    padding: 15px;
  `}
`;
export const ProductCardImage = styled.div `
  flex-grow: 2;
  min-width: 50%;
`;
export const ProductCardInfo = styled.div `
  flex-grow: 2;
  padding: 15px 0 15px 70px;
  ${(props) => props.theme.mixins.mobile `
    padding: 15px 0;
  `}
`;
export const ProductCardPriceWrapper = styled.div `
  display: flex;
  align-items: center;
  padding: 10px 0 25px;
`;
export const ProductCardPrice = styled.div `
  color: ${(props) => props.theme.colors.primary};
  margin: 0 10px;
  font-size: 2.42rem;
  line-height: 3.21rem;
  font-weight: bold;
  margin-block: 0.67em;
`;
export const ProductCardSpecs = styled.ul `
  margin-bottom: 20px;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid #bababa;
    }

    padding: 15px 0;
  }
`;
export const StyledButton = styled(Button) `
  max-width: 250px;
  color: ${(props) => props.blackBtnColor ? 'black' : props.theme.colors.primary};
  border-color: black;
  font-weight: bold;

  @media (max-width: 450px) {
    width: 100%;
    max-width: unset;
  }
`;
export const InfoIcon = styled.div `
  padding-left: 5px;
`;
