import styled from 'styled-components';
import { ThumbnailUtils } from '@adsel/libstorefront';
export const FooterContainer = styled.footer `
  width: 100%;
  background-color: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  background-size: 45%;
  background-position: right;
  background-repeat: no-repeat;
  background-image: url(${ThumbnailUtils.getImageSrc({
    image: '/assets/footer_bg.png',
    width: 500,
    height: 426,
    entity: 'frontend',
})});

  ${(props) => props.theme.mixins.mobile(`
    background-image: url('/assets/image/footer_bg_mobile.png');
    background-position: center top;
    background-size: 100%;
  `)}
`;
export const FooterContentContainer = styled.div `
  max-width: 1140px;
  padding: 3em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  ${(props) => props.theme.mixins.mobile('padding: 3em 15px;')}
`;
export const FooterColumn = styled.div `
  width: 33%;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '0px')};
  ${(props) => props.theme.mixins.mobile(`
    width: 100%;
    padding-left: 0;
  `)}
`;
export const FooterHeading = styled.h5 `
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  ${(props) => props.theme.mixins.mobile('font-size: 16px;')}
`;
export const FooterTextContainer = styled.p `
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  margin: 2.8em 0 0 0;
  max-width: 80%;
  ${(props) => props.theme.mixins.mobile(`
    max-width: 100%;
    margin: 2em 0 0 0;
    line-height: 28px;
  `)}
`;
export const FooterContactButtonContainer = styled.div `
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2em;
  ${(props) => props.theme.mixins.mobile('font-size: 16px;')}
`;
export const OpenColumnButton = styled.button `
  width: 100%;
  padding: 0;
  color: #fff;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  line-height: 1rem;
  margin-bottom: 30px;
  svg {
    fill: #fff !important;
    position: relative;
    right: -20px;
  }
  h5 {
    margin-bottom: 0;
    margin-right: 10px;
  }
`;
export const FooterMobileContent = styled.div `
  display: none;
  ${(props) => props.theme.mixins.mobile(`
    display: block;
    width: 100%;
  `)}
`;
export const FooterDesktopContent = styled.div `
  display: block;
  ${(props) => props.theme.mixins.mobile(`
    display: none;
  `)}
`;
export const FooterLocationsContainer = styled.div `
  font-size: 14px;
  line-height: 22px;
  color: #fff;
  ${(props) => props.theme.mixins.mobile('margin-bottom: 20px;')}
`;
export const FooterLocationsHeader = styled.p `
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
`;
export const FooterNavigationContainer = styled.ul `
  ${(props) => props.theme.mixins.mobile('margin-bottom: 30px;')}
  li {
    margin-bottom: 15px;
  }
`;
export const NavigationItem = styled.p `
  font-weight: 500;
  font-size: 1.14rem;
  line-height: 1.85rem;
  margin-bottom: 0;
  ${(props) => props.theme.mixins.mobile('font-size: 16px;')}
`;
export const MailPhone = styled.a `
  display: inline-block;
  text-decoration: underline;
  ${(props) => props.paddingBottom &&
    `
    padding-bottom: ${props.paddingBottom};
  `}
`;
export const FooterLocationsWrapper = styled.div `
  display: flex;
  flex-direction: column;
`;
